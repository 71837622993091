import React from "react"

import Layout from "../../../layouts/default"

import Breadcrumb from "../../../components/breadcrumb"
import Paragraph from "../../../components/paragraph"
import PageTitle from "../../../components/page-title"
import Seo from "../../../components/seo"
import Stack from "../../../components/stack"
import MediaguideLeichteSprache from "../../../content/shared/mediaguide-leichte-sprache"

const LeichteSpracheMediaguide = () => {
  return (
    <Layout backdrop="mediaguide">
      <Seo
        title="Media-Guide"
        description="Im Hölderlin∙turm gibt es einen Media-Guide in Leichter Sprache."
      />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Leichte Sprache",
                link: "/leichte-sprache",
              },
              {
                title: "Besuch",
                link: "/leichte-sprache/besuch",
              },
              {
                title: "Media-Guide",
                link: "/leichte-sprache/besuch/mediaguide",
              },
            ]}
          />
          <PageTitle>Media-Guide</PageTitle>
          <Paragraph>
            Im Hölderlin∙turm gibt es einen Media-Guide in Leichter Sprache.{" "}
            <br />
            Media-Guide bedeutet: <br />
            Sie können mit einem kleinen Gerät Texte anhören. <br />
            Es gibt Texte in Leichter Sprache zu jedem Raum. <br />
          </Paragraph>
        </Stack>
        <MediaguideLeichteSprache />
      </Stack>
    </Layout>
  )
}

export default LeichteSpracheMediaguide
